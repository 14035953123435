import { cellStyles } from 'constants/spots';

import { IPodcastSpotsTable } from 'interfaces/spots/Podcast/IPodcastSpotsTable';

import { colors } from 'styles/globalStyles';

import ActivityStatus from '../ActivityStatus';
import Cell from '../Cell';

import { CustomIcon, OverflowTip } from 'components/UIComponents';
import { SplitLink, SplitLinkText } from '../../ActionsPanel/styledComponents';

interface Props {
  spot: IPodcastSpotsTable;
}

const ActivitiesCells = ({ spot }: Props) => {
  const splitLinkMatch = spot.comment?.match(/Split \(\d{2}\/\d{2}\/\d{4}\)/);
  const splitLink = splitLinkMatch ? splitLinkMatch[0] : 'Split';
  return (
    <>
      <Cell
        cellProps={{ style: cellStyles.Podcast.testShow }}
        content={
          <>
            <ActivityStatus active={spot.is_test_show} title="Test show" activeColor={colors.darkGrey} />
            <div style={{ marginLeft: 17}}>
              <OverflowTip title={spot.is_digitally_inserted}>{spot.is_digitally_inserted}</OverflowTip>
            </div>
          </>
        }
      />
      <Cell
        cellProps={{ style: cellStyles.Podcast.pixelRequired }}
        content={
          <>
            {spot.split_from_spot_id ? (
              <SplitLink href={`/spots/podcast?spotId=${spot.split_from_spot_id}`} target="_blank">
                <CustomIcon color={colors.red} name="link" />
                <SplitLinkText>
                  {splitLink}
                </SplitLinkText>
              </SplitLink>
            ) : ''}
          </>
        }
      />
    </>
  );
};

export default ActivitiesCells;
