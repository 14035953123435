import * as Yup from 'yup';
import VALIDATION_MESSAGES from 'constants/validationMessages';
import { minValue } from 'constants/fieldsLimits';

export const podcastSplitValidationSchema = Yup.object({
  startDate: Yup.string()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .test(
      'start-date-in-range',
      'start date must be fall within the date range of the original spot',
      function (value) {
        const inputElement = document.querySelector('input[name="startDate"]') as HTMLInputElement | null;
        const originalStartDate = inputElement?.placeholder;

        if (originalStartDate && value) {
          const parsedOriginalStartDate = new Date(originalStartDate);
          const parsedCurrentStartDate = new Date(value);

          return parsedCurrentStartDate >= parsedOriginalStartDate;
        }
        return true;
      }
    )
    .test(
      'start-date-before-end-date',
      'start date cannot be later than end date',
      function (value) {
        const endDateElement = document.querySelector('input[name="endDate"]') as HTMLInputElement | null;
        const endDateValue = endDateElement?.value;

        if (value && endDateValue) {
          const parsedStartDate = new Date(value);
          const parsedEndDate = new Date(endDateValue);

          return parsedStartDate <= parsedEndDate;
        }
        return true;
      }
    ),

  endDate: Yup.string()
    .optional()
    .nullable()
    .test(
      'end-date-in-range',
      'end date must be fall within the date range of the original spot',
      function (value) {
        const startDateElement = document.querySelector('input[name="startDate"]') as HTMLInputElement | null;
        const originalStartDate = startDateElement?.placeholder;

        const endDateElement = document.querySelector('input[name="endDate"]') as HTMLInputElement | null;
        const originalEndDate = endDateElement?.placeholder;

        if (originalStartDate && originalEndDate && value) {
          const parsedOriginalStartDate = new Date(originalStartDate);
          const parsedOriginalEndDate = new Date(originalEndDate);
          const parsedCurrentEndDate = new Date(value);

          if (parsedCurrentEndDate < parsedOriginalStartDate) {
            return false;
          }

          if (originalEndDate !== 'mm/dd/yyyy') {
            return parsedCurrentEndDate <= parsedOriginalEndDate;
          }
        }
        return true;
      }
    )
    .test(
      'end-date-after-start-date',
      'end date cannot be earlier than start date',
      function (value) {
        const startDateElement = document.querySelector('input[name="startDate"]') as HTMLInputElement | null;
        const startDateValue = startDateElement?.value;

        if (value && startDateValue) {
          const parsedEndDate = new Date(value);
          const parsedStartDate = new Date(startDateValue);

          return parsedEndDate >= parsedStartDate;
        }
        return true;
      }
    ),
  impressions: Yup.number()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .typeError(VALIDATION_MESSAGES.NUMBER)
    .integer(VALIDATION_MESSAGES.INTEGER)
    .min(minValue, VALIDATION_MESSAGES.POSITIVE)
    .test(
      'max-impressions',
      'the value must not exceed impressions of original spot',
      function (value) {
        const inputElement = document.querySelector('input[name="impressions"]') as HTMLInputElement | null;
        const placeholder = inputElement?.placeholder;
        if (placeholder && value !== undefined) {
          const maxValue = parseInt(placeholder, 10);
          return value < maxValue;
        }
        return true;
      }
    ),
  grossRate: Yup.number()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .typeError(VALIDATION_MESSAGES.NUMBER)
    .min(minValue, VALIDATION_MESSAGES.POSITIVE)
    .test(
      'max-grossRate',
      'the value must not exceed gross rate of original spot',
      function (value) {
        const inputElement = document.querySelector('input[name="grossRate"]') as HTMLInputElement | null;
        const placeholder = inputElement?.placeholder;
        if (placeholder && value !== undefined) {
          const maxValue = parseInt(placeholder, 10);
          return value < maxValue;
        }
        return true;
      }
    )
    .test(
      'is valid',
      VALIDATION_MESSAGES.FRACTIONAL_NUMBER_MAX_LENGTH('7', '2'),
      (value: number | undefined) =>
        /^[0-9]{0,7}[.]{0,1}[0-9]{0,2}$/.test(String(value))
    ),
});
