import { useContext, useState } from 'react';

import { OrderAPI } from 'api/orderAPI';

import { LinkButton } from 'components/UIComponents';
import Modal from 'components/base/BaseModal';
import OrderForm from 'components/forms/OrderForm';

import { BulkSelectContext } from 'context/BulkSelectContext';
import { FilterContext } from 'context/FilterContext';
import { ModalContext } from 'context/ModalContext';

import { SpotType } from 'constants/enums/SpotTypes';
import RESPONSE_CODES from 'constants/responseCodes';

import { IGroupForOrdersSuccessfulResponseData, IOrderGroups } from 'interfaces/Order/IOrderForm';
import { IAPIError, IResponse } from 'interfaces/api';
import { usePopup } from 'context/GlobalPopupContext';
import { apiErrorToPopupData } from 'helpers/utils';

export const CREATE_ORDER_FORM_MODAL_NAME = 'createOrderFormModal';

const CreateOrderAction = ({ type }: { type: SpotType }) => {
  const { selectAll, excludedIds, ids, splitSpotIds } = useContext(BulkSelectContext);
  const { getSerializedData } = useContext(FilterContext);
  const { onModalClose, onModalOpen } = useContext(ModalContext);
  const { openPopup, openSequentialPopups } = usePopup();
  const [orderGroupsInfo, setOrderGroupsInfo] = useState<IOrderGroups | null>();

  const onCreateOrderClick = () => {
    OrderAPI.groupSpots({ selectAll, excludedIds, ids }, getSerializedData(), type).then(handleResponse);
  };

  const handleResponse = (response: IResponse) => {
    if (response.status === RESPONSE_CODES.UNPROCESSABLE_ENTITY) {
      openPopup(response.data.error);
    } else {
      const data = response.data as IGroupForOrdersSuccessfulResponseData;
      setOrderGroupsInfo(response.data.data as IOrderGroups);

      if (data.meta) {
        openSequentialPopups(data.meta, () => {
          onModalOpen(CREATE_ORDER_FORM_MODAL_NAME);
        });
      } else {
        onModalOpen(CREATE_ORDER_FORM_MODAL_NAME);
      }
    }
  };

  const onOrderModalClose = (error?: IAPIError) => {
    if (error) {
      let popupData = apiErrorToPopupData(error);
      openPopup({
        ...popupData,
      });
    }
    onModalClose(CREATE_ORDER_FORM_MODAL_NAME);
    setOrderGroupsInfo(null);
  };

  return (
    <>
      <LinkButton
        onClick={onCreateOrderClick}
        disabled={!selectAll && ids.every((item) => splitSpotIds?.includes(item))}>
        Create Order
      </LinkButton>
      {orderGroupsInfo && (
        <Modal
          name={CREATE_ORDER_FORM_MODAL_NAME}
          title="Order Details"
          onModalClose={() => {
            onModalClose(CREATE_ORDER_FORM_MODAL_NAME);
            setOrderGroupsInfo(null);
          }}
        >
          <OrderForm type={type} data={orderGroupsInfo} onClose={onOrderModalClose} />
        </Modal>
      )}
    </>
  );
};

export default CreateOrderAction;
