import { FormGroup, FormControlLabel } from '@mui/material';

import styled from '@emotion/styled';

import { colors, fontFamily } from 'styles/globalStyles';

export const PanelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const StyledFormGroup = styled(FormGroup)`
  padding-left: 19px;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
    margin-left: 16px;
    font-weight: 500;
    font-size: 14px;
    font-family: ${fontFamily};
    letter-spacing: 0.32px;
    color: ${colors.darkGrey};
  }
`;

export const LinkButtonText = styled.span`
  margin-left: 10px;
`;

export const SplitLinkText = styled.span`
  margin-top: 1px;
  font-size: 11px;
  margin-left: 1px;
`;

export const SplitLink = styled.a`
  display: flex;
  align-items: center;
  color: #da3832
`;